import { Layouts } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		...Layouts.horizontalStack(6),
	},
	star: {},
	starAnimatable: {
		transition: 'all 0.2s ease',
	},
	starHover: {
		':hover': {
			transform: 'scale3d(1.2, 1.2, 1)',
		},
	},
});
