import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { StarIcon } from '../../svgs/icons/StarIcon';
import { styleSheet } from './styles';

interface IProps {
	animateRatingChanges?: boolean;
	className?: string;
	fillColor?: string;
	onValueChanged?(value: number): void;
	readonly?: boolean;
	starStyles?: StyleDeclarationValue[];
	strokeColor?: string;
	styles?: StyleDeclarationValue[];
	total: number;
	value?: number;
}

export const StarRating: React.FC<IProps> = props => {
	const {
		animateRatingChanges,
		className,
		fillColor,
		onValueChanged,
		readonly,
		starStyles = [],
		strokeColor,
		styles = [],
		total,
		value,
	} = props;

	const canAnimateRatingChanges = animateRatingChanges && !readonly;
	const [rating, setRating] = React.useState<number>(value || 0);
	const onStarClicked = React.useCallback(
		(index: number) => (e: React.MouseEvent<HTMLElement>) => {
			const nextValue = index + 1;
			if (onValueChanged) {
				onValueChanged(nextValue);
			} else {
				setRating(nextValue);
			}
			e.preventDefault();
			e.stopPropagation();
		},
		[onValueChanged]
	);

	React.useEffect(() => {
		// @ts-ignore
		if (value >= 0) {
			// @ts-ignore
			setRating(value);
		}
	}, [value]);

	const animationStarStyles = canAnimateRatingChanges ? [styleSheet.starAnimatable, styleSheet.starHover] : [];

	const [, setHovering] = React.useState<boolean>(false);
	const [hoverIndex, setHoverIndex] = React.useState<number>(-1);
	const onToggleHover = React.useCallback(
		(on: boolean) => () => {
			setHovering(on);
			if (!on) {
				setHoverIndex(-1);
			}
		},
		[]
	);

	const onToggleStarHover = React.useCallback(
		(on: boolean, index: number) => () => {
			if (on) {
				setHoverIndex(index);
			}
		},
		[]
	);

	return (
		<div
			className={`${css(styleSheet.container, ...styles)} star-rating ${className || ''}`}
			// @ts-ignore
			onMouseEnter={canAnimateRatingChanges && onToggleHover(true)}
			// @ts-ignore
			onMouseLeave={canAnimateRatingChanges && onToggleHover(false)}
		>
			{Array(total)
				.fill(null)
				.map((__, i) => {
					const filled = hoverIndex >= 0 ? i <= hoverIndex : i < rating;
					const icon = (
						<StarIcon
							fillColor={fillColor}
							filled={filled}
							// @ts-ignore
							onMouseEnter={canAnimateRatingChanges && onToggleStarHover(true, i)}
							// @ts-ignore
							onMouseLeave={canAnimateRatingChanges && onToggleStarHover(false, i)}
							strokeColor={strokeColor}
							styles={[styleSheet.star, ...animationStarStyles, ...starStyles]}
						/>
					);
					if (readonly) {
						return <span key={`${i}`}>{icon}</span>;
					}
					return (
						<button key={`${i}`} onClick={onStarClicked(i)}>
							{icon}
						</button>
					);
				})}
		</div>
	);
};
